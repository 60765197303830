import { IConfigHt } from '@theia-cc/ht/state';
import { CONFIG_DEFAULT_CONTACT_PHONE } from '@theia-cc/shared/helpers';
import { IEnvVariablesBase, ILanguageSpecific } from '@theia-cc/shared/models';
import { STEPS_DEFAULT } from './steps-default';
import { ALL_TEMPLATES } from './templates';

const CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS: ILanguageSpecific<string> = {
  de: './assets/img/offerMockup/helion/helion-offer-mockup-de.png',
  fr: './assets/img/offerMockup/helion/helion-offer-mockup-fr.png',
  it: './assets/img/offerMockup/helion/helion-offer-mockup-it.png',
};

const CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS: ILanguageSpecific<string> = {
  de: './assets/img/offerMockup/partners/mandant-offer-mockup-de.jpg',
  fr: './assets/img/offerMockup/partners/mandant-offer-mockup-fr.jpg',
  it: './assets/img/offerMockup/partners/mandant-offer-mockup-it.jpg',
};

const CONFIG_MT_OFFER_MOCKUP_IMAGE_URLS: ILanguageSpecific<string> = {
  de: './assets/img/offerMockup/mt/mt-offer-mockup-de.jpg',
  fr: './assets/img/offerMockup/mt/mt-offer-mockup-fr.jpg',
  it: './assets/img/offerMockup/mt/mt-offer-mockup-it.jpg',
};

// Supported partner for ht-new
export const CONFIG_PARTNER_KEYS = {
  default: 'DEFAULT',
  brack: 'BRACK',
  byes: 'BYES',
  hausinfo: 'HAUSINFO',
  ikea: 'IKEA',
  jumbo: 'JUMBO',
  ekz: 'EKZ',
  egb: 'EGB',
  mt: 'MT',
};

const CONFIG_DEFAULT_ENVVARIABLES: IEnvVariablesBase = {
  appKey: '6452E6F74AFF44A4978ED983A8CCB420',
  enablePhoneHeader: true,
  enableCallbackHeader: true,

  localeStyle: 'formal',
};

export const WIZARD_CONFIG_HT: { [key: string]: IConfigHt } = {
  default: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion.svg',
      faviconUrl: './assets/favicon/helion.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.helion.ch/wp-danke/',
        fr: 'https://www.helion.ch/fr/wp-merci/',
        it: 'https://www.helion.ch/it/wp-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  brack: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-brack.png',
      faviconUrl: './assets/favicon/favicon.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.helion.ch/wp-danke/',
        fr: 'https://www.helion.ch/fr/wp-merci/',
        it: 'https://www.helion.ch/it/wp-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  byes: {
    org: {
      name: 'Bouygues E&S InTec',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.bouygues-offerte.ch',
        fr: 'https://www.bouygues-offerte.ch',
        it: 'https://www.bouygues-offerte.ch',
      },
      logoImageUrl: './assets/img/header/logo-byes.png',
      faviconUrl: './assets/favicon/byes.ico',
      stylesheetUrl: 'byes.css',
      contactOfferImageUrls: CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.bouygues-offerte.ch/de/danke',
        fr: 'https://www.bouygues-offerte.ch/fr/merci',
        it: 'https://www.bouygues-offerte.ch/it/grazie',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '968a9f8fe2394083ae9ddc04ecc12acf',
      enablePhoneHeader: true,
      enableCallbackHeader: true,
      localeStyle: 'formal',
    },
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  egb: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo_helion_egb.jpg',
      faviconUrl: './assets/favicon/helion.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.helion.ch/wp-danke/',
        fr: 'https://www.helion.ch/fr/wp-merci/',
        it: 'https://www.helion.ch/it/wp-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  hausinfo: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-hausinfo.png',
      faviconUrl: './assets/favicon/favicon.ico',
      stylesheetUrl: 'hausinfo.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.helion.ch/wp-danke/',
        fr: 'https://www.helion.ch/fr/wp-merci/',
        it: 'https://www.helion.ch/it/wp-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  ikea: {
    org: {
      name: 'IKEA Solstrale',
      contactPhone: {
        de: '+41 32 677 55 04',
        fr: '+41 32 677 55 04',
        it: '+41 32 677 55 04',
      },
      contactEmail: 'solstrale@helion-solar.ch',
      logoLinkUrl: {
        de: 'https://www.ikea.ch',
        fr: 'https://www.ikea.ch',
        it: 'https://www.ikea.ch',
      },
      logoImageUrl: './assets/img/header/logo-ikea.png',
      faviconUrl: './assets/favicon/ikea.ico',
      stylesheetUrl: 'ikea.css',
      contactOfferImageUrls: CONFIG_PARTNERS_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.ikea.com/ch/de/campaigns/vielen-dank-fuer-dein-interesse-an-einer-waermepumpe-pubbef5d87a',
        fr: 'https://www.ikea.com/ch/fr/campaigns/merci-de-ton-interet-pour-une-pompe-a-chaleur-pubbef5d87a',
        it: 'https://www.ikea.com/ch/it/campaigns/grazie-del-tuo-interesse-in-una-pompa-di-calore-pubbef5d87a',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: 'D3B4BC369D8F46A48AB7468ED37B22B0',
      enablePhoneHeader: false,
      enableCallbackHeader: false,
      localeStyle: 'informal',
    },
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  jumbo: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-jumbo.png',
      faviconUrl: './assets/favicon/helion.ico',
      stylesheetUrl: 'jumbo.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: '',
        fr: '',
        it: '',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  ekz: {
    org: {
      name: 'EKZ',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.ekz.ch',
        fr: 'https://www.ekz.ch',
        it: 'https://www.ekz.ch',
      },
      logoImageUrl: './assets/img/header/logo-ekz.jpg',
      faviconUrl: './assets/favicon/ekz.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: '',
        fr: '',
        it: '',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '20CE39BBB7E44BF30260938B68AD2BD5',
      enablePhoneHeader: true,
      enableCallbackHeader: true,
      localeStyle: 'formal',
    },
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  mt: {
    org: {
      name: 'Meier Tobler',
      contactPhone: {
        de: '0800 846 800',
        fr: '0800 846 800',
        it: '0800 846 800',
        en: '0800 846 800',
      },
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.meiertobler.ch/',
        fr: 'https://www.meiertobler.ch/',
        it: 'https://www.meiertobler.ch/',
      },
      logoImageUrl: './assets/img/header/logo-mt.svg',
      faviconUrl: './assets/favicon/mt.ico',
      stylesheetUrl: 'mt.css',
      contactOfferImageUrls: CONFIG_MT_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.meiertobler.ch/de/heizungs-konfigurator-anfrage-versendet/',
        fr: 'https://www.meiertobler.ch/fr/configurateur-demande-transmise/',
        it: 'https://www.meiertobler.ch/it/configuratore-richiesta-inviata/',
      },
    },
    envVariables: {
      appKey: '3c033653068044d591a212b68afd4dc8',
      enablePhoneHeader: true,
      enableCallbackHeader: true,
      localeStyle: 'formal',
    },
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  migrosbank: {
    org: {
      name: 'Migrosbank',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: [
        './assets/img/header/logo-helion.svg',
        './assets/img/header/logo_migrosbank.png',
      ],
      faviconUrl: './assets/favicon/helion.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.migrosbank.ch/kampagnen/energetisch-erneuern/abschluss-helion/',
        fr: 'https://www.migrosbank.ch/fr/campagnes/renover-energetiquement/finalisation-hellion/',
        it: 'https://www.migrosbank.ch/it/campagne/rinnovare-energeticamente/finalizzazione-helion/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: CONFIG_DEFAULT_ENVVARIABLES,
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
  test: {
    org: {
      name: 'Helion',
      contactPhone: CONFIG_DEFAULT_CONTACT_PHONE,
      contactEmail: 'sales@helion.ch',
      logoLinkUrl: {
        de: 'https://www.helion.ch',
        fr: 'https://www.helion.ch',
        it: 'https://www.helion.ch',
      },
      logoImageUrl: './assets/img/header/logo-helion.svg',
      faviconUrl: './assets/favicon/helion.ico',
      stylesheetUrl: 'default.css',
      contactOfferImageUrls: CONFIG_HELION_OFFER_MOCKUP_IMAGE_URLS,
      successPageUrls: {
        de: 'https://www.helion.ch/wp-danke/',
        fr: 'https://www.helion.ch/fr/wp-merci/',
        it: 'https://www.helion.ch/it/wp-grazie/',
      },
      contactPageUrls: {
        de: 'https://www.helion.ch/de/offer-rueckruf/',
        fr: 'https://www.helion.ch/fr/offer-rappel/',
        it: 'https://www.helion.ch/it/offer-richiamo/',
      },
    },
    envVariables: {
      appKey: '2498a14ddfb24f94bccd4983869ffcaf',
      enablePhoneHeader: true,
      enableCallbackHeader: true,
      localeStyle: 'formal',
    },
    defaultLanguage: 'de',
    steps: STEPS_DEFAULT,
    templatesHt: ALL_TEMPLATES,
  },
};
