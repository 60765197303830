import { NboHeatingLeadRequest } from '@api-cc';
import { IStep } from '@theia-cc/shared/models';
import { newVersion, skipCompareTechnologies } from '@theia-cc/shared/helpers';

export const STEPS_DEFAULT: IStep[] = [
  {
    name: 'heatingtype',
  },
  {
    name: 'heatemission',
  },
  {
    name: 'familymembercount',
  },
  {
    name: 'insulation',
  },
  {
    name: 'addresslookup',
  },
  {
    name: 'consumption',
  },
  {
    name: 'contactlargesystem',
    skipIf: [{ largeSystem: false }],
  },
  {
    name: 'contactaddress',
    skipIf: [
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.OilBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.GasBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.HeatPump,
      },
      { heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.Other },
    ],
  },
  {
    name: 'heatingarea',
    skipIf: [
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.OilBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.GasBoiler,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.CentralStorageHeating,
      },
      {
        heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.HeatPump,
      },
      { heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.Other },
    ],
  },
  {
    name: 'comparison',
    skipIf: [skipCompareTechnologies],
  },
  {
    name: 'contactemail',
    skipIf: [newVersion],
  },
  {
    name: 'contactcondensed',
    skipIf: [newVersion],
  },
  {
    name: 'offerpreview',
  },
  {
    name: 'consulting',
  },
];
