import { NboHeatingVariantRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ITemplateHt } from '@theia-cc/ht/core';

export const ALL_TEMPLATES: ITemplateHt[] = [
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Mitsubishi,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.MITSUBISHI.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.MITSUBISHI.TITLE'),
    icon: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: 'assets/img/nbo/mitsubishi-thumbnail.jpg',
      AirWaterHeatPumpSplit: 'assets/img/nbo/mitsubishi-thumbnail.jpg',
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    logo: 'assets/img/nbo/logo-bosch.jpg',
    description: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.MITSUBISHI.DESCRIPTION_OUTSIDE'
      ),
      AirWaterHeatPumpSplit: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.MITSUBISHI.DESCRIPTION_SPLIT'
      ),
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    recommended: true,
    data: {},
    images: {
      AirWaterHeatPumpInside: [],
      AirWaterHeatPumpOutside: [
        'assets/img/nbo/mitsubishi-1.jpg',
        'assets/img/nbo/mitsubishi-2.jpg',
        'assets/img/nbo/mitsubishi-thumbnail.jpg',
      ],
      AirWaterHeatPumpSplit: [
        'assets/img/nbo/mitsubishi-1.jpg',
        'assets/img/nbo/mitsubishi-3.jpeg',
        'assets/img/nbo/mitsubishi-2.jpg',
        'assets/img/nbo/mitsubishi-thumbnail.jpg',
      ],
      BrineWaterHeatPump: [],
      GasBoilerFloor: [],
      GasBoilerWall: [],
      NotSpecified: [],
      OilBoiler: [],
      PelletsBoiler: [],
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Stiebel,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.TITLE'),
    icon: {
      AirWaterHeatPumpInside: 'assets/img/nbo/stiebel-inside-thumbnail.jpg',
      AirWaterHeatPumpOutside: 'assets/img/nbo/stiebel-thumbnail.jpg',
      AirWaterHeatPumpSplit: 'assets/img/nbo/stiebel-thumbnail.jpg',
      BrineWaterHeatPump: 'assets/img/nbo/stiebel-brine-thumbnail.jpg',
      GasBoilerFloor: 'assets/img/nbo/stiebel-thumbnail.jpg',
      GasBoilerWall: 'assets/img/nbo/stiebel-thumbnail.jpg',
      NotSpecified: 'assets/img/nbo/stiebel-thumbnail.jpg',
      OilBoiler: 'assets/img/nbo/stiebel-thumbnail.jpg',
      PelletsBoiler: '',
    },
    logo: 'assets/img/nbo/logo-stiebel-eltron.jpg',
    description: {
      AirWaterHeatPumpInside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      AirWaterHeatPumpOutside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      AirWaterHeatPumpSplit: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      BrineWaterHeatPump: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.BRINE_WATER_HEAT_PUMP.DESCRIPTION'
      ),
      GasBoilerFloor: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      GasBoilerWall: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      NotSpecified: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      OilBoiler: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.STIEBEL.DESCRIPTION'),
      PelletsBoiler: '',
    },
    recommended: true,
    data: {},
    images: {
      AirWaterHeatPumpInside: ['assets/img/nbo/stiebel-5.jpg', 'assets/img/nbo/stiebel-8.jpg'],
      AirWaterHeatPumpOutside: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      AirWaterHeatPumpSplit: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      BrineWaterHeatPump: ['assets/img/nbo/stiebel-6.jpg', 'assets/img/nbo/stiebel-7.jpg'],
      GasBoilerFloor: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-2.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      GasBoilerWall: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-2.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      NotSpecified: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-2.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      OilBoiler: [
        'assets/img/nbo/stiebel-1.jpg',
        'assets/img/nbo/stiebel-2.jpg',
        'assets/img/nbo/stiebel-3.jpg',
        'assets/img/nbo/stiebel-4.jpg',
      ],
      PelletsBoiler: [],
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Hoval,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.TITLE'),
    icon: {
      AirWaterHeatPumpInside: 'assets/img/nbo/hoval-inside-thumbnail.jpg',
      AirWaterHeatPumpOutside: 'assets/img/nbo/hoval-thumbnail.jpg',
      AirWaterHeatPumpSplit: 'assets/img/nbo/hoval-thumbnail.jpg',
      BrineWaterHeatPump: 'assets/img/nbo/hoval-brine-thumbnail.jpg',
      GasBoilerFloor: 'assets/img/nbo/hoval-thumbnail.jpg',
      GasBoilerWall: 'assets/img/nbo/hoval-thumbnail.jpg',
      NotSpecified: 'assets/img/nbo/hoval-thumbnail.jpg',
      OilBoiler: 'assets/img/nbo/hoval-thumbnail.jpg',
      PelletsBoiler: '',
    },
    logo: 'assets/img/nbo/logo-hoval.jpg',
    description: {
      AirWaterHeatPumpInside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      AirWaterHeatPumpOutside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      AirWaterHeatPumpSplit: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      BrineWaterHeatPump: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      GasBoilerFloor: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      GasBoilerWall: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      NotSpecified: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      OilBoiler: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.HOVAL.DESCRIPTION'),
      PelletsBoiler: '',
    },
    recommended: false,
    data: {},
    images: {
      AirWaterHeatPumpInside: [
        'assets/img/nbo/hoval-6.jpg',
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      AirWaterHeatPumpOutside: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      AirWaterHeatPumpSplit: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      BrineWaterHeatPump: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      GasBoilerFloor: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      GasBoilerWall: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      NotSpecified: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      OilBoiler: [
        'assets/img/nbo/hoval-1.jpg',
        'assets/img/nbo/hoval-2.jpg',
        'assets/img/nbo/hoval-3.jpg',
        'assets/img/nbo/hoval-4.jpg',
        'assets/img/nbo/hoval-5.jpg',
      ],
      PelletsBoiler: [],
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Oertli,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.TITLE'),
    icon: {
      AirWaterHeatPumpInside: 'assets/img/nbo/oertli-thumbnail-inside.jpg',
      AirWaterHeatPumpOutside: 'assets/img/nbo/oertli-thumbnail-outside.jpg',
      AirWaterHeatPumpSplit: 'assets/img/nbo/oertli-thumbnail-split.png',
      BrineWaterHeatPump: 'assets/img/nbo/oertli-thumbnail-brine.jpg',
      GasBoilerFloor: 'assets/img/nbo/oertli-8.jpg',
      GasBoilerWall: 'assets/img/nbo/oertli-8.jpg',
      NotSpecified: 'assets/img/nbo/oertli-thumbnail.jpg',
      OilBoiler: 'assets/img/nbo/oertli-7.jpg',
      PelletsBoiler: '',
    },
    logo: 'assets/img/nbo/logo-oertli.png',
    description: {
      AirWaterHeatPumpInside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION'),
      AirWaterHeatPumpOutside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      AirWaterHeatPumpSplit: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION'),
      BrineWaterHeatPump: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION'),
      GasBoilerFloor: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION_GAS_FLOOR'),
      GasBoilerWall: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION_GAS_WALL'),
      NotSpecified: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION'),
      OilBoiler: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OERTLI.DESCRIPTION_OIL'),
      PelletsBoiler: '',
    },
    recommended: false,
    data: {},
    images: {
      AirWaterHeatPumpInside: [
        'assets/img/nbo/oertli-10.jpg',
        'assets/img/nbo/oertli-11.jpg',
        'assets/img/nbo/oertli-12.jpg',
        'assets/img/nbo/oertli-13.jpg',
        'assets/img/nbo/oertli-14.jpg',
      ],
      AirWaterHeatPumpOutside: ['assets/img/nbo/oertli-22.jpg', 'assets/img/nbo/oertli-23.jpg'],
      AirWaterHeatPumpSplit: [
        'assets/img/nbo/oertli-19.png',
        'assets/img/nbo/oertli-20.png',
        'assets/img/nbo/oertli-21.png',
      ],
      BrineWaterHeatPump: [
        'assets/img/nbo/oertli-15.jpg',
        'assets/img/nbo/oertli-16.jpg',
        'assets/img/nbo/oertli-17.jpg',
        'assets/img/nbo/oertli-18.jpg',
      ],
      GasBoilerFloor: ['assets/img/nbo/oertli-9.jpg'],
      GasBoilerWall: ['assets/img/nbo/oertli-8.jpg'],
      NotSpecified: [
        'assets/img/nbo/oertli-1.jpg',
        'assets/img/nbo/oertli-2.jpg',
        'assets/img/nbo/oertli-3.jpg',
      ],
      OilBoiler: [
        'assets/img/nbo/oertli-4.jpg',
        'assets/img/nbo/oertli-5.jpg',
        'assets/img/nbo/oertli-6.jpg',
        'assets/img/nbo/oertli-7.jpg',
      ],
      PelletsBoiler: [],
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Bosch,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.TITLE'),
    icon: {
      AirWaterHeatPumpInside: 'assets/img/nbo/bosch-air-water-outside-thumbnail.jpg',
      AirWaterHeatPumpOutside: 'assets/img/nbo/bosch-air-water-outside-thumbnail.jpg',
      AirWaterHeatPumpSplit: 'assets/img/nbo/bosch-air-water-outside-thumbnail.jpg',
      BrineWaterHeatPump: 'assets/img/nbo/bosch-brine-thumbnail.jpg',
      GasBoilerFloor: 'assets/img/nbo/bosch-gas-wall-thumbnail.jpg',
      GasBoilerWall: 'assets/img/nbo/bosch-gas-wall-thumbnail.jpg',
      NotSpecified: 'assets/img/nbo/bosch-thumbnail.jpg',
      OilBoiler: 'assets/img/nbo/bosch-oil-thumbnail.jpg',
      PelletsBoiler: '',
    },
    logo: 'assets/img/nbo/logo-bosch.jpg',
    description: {
      AirWaterHeatPumpInside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      AirWaterHeatPumpOutside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      AirWaterHeatPumpSplit: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      BrineWaterHeatPump: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.MITSUBISHI.DESCRIPTION_OUTSIDE'
      ),
      GasBoilerFloor: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_GAS_FLOOR'),
      GasBoilerWall: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_GAS_WALL'),
      NotSpecified: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION'),
      OilBoiler: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.BOSCH.DESCRIPTION_OIL'),
      PelletsBoiler: '',
    },
    recommended: false,
    data: {},
    images: {
      AirWaterHeatPumpInside: ['assets/img/nbo/bosch-14.jpg', 'assets/img/nbo/bosch-15.jpg'],
      AirWaterHeatPumpOutside: ['assets/img/nbo/bosch-14.jpg', 'assets/img/nbo/bosch-15.jpg'],
      AirWaterHeatPumpSplit: ['assets/img/nbo/bosch-14.jpg', 'assets/img/nbo/bosch-15.jpg'],
      BrineWaterHeatPump: [
        'assets/img/nbo/bosch-13.jpg',
        'assets/img/nbo/bosch-18.jpg',
        'assets/img/nbo/bosch-19.jpg',
        'assets/img/nbo/bosch-20.jpg',
      ],
      GasBoilerFloor: [
        'assets/img/nbo/bosch-10.jpg',
        'assets/img/nbo/bosch-11.jpg',
        'assets/img/nbo/bosch-12.jpg',
      ],
      GasBoilerWall: [
        'assets/img/nbo/bosch-7.jpg',
        'assets/img/nbo/bosch-8.jpg',
        'assets/img/nbo/bosch-9.jpg',
      ],
      NotSpecified: [
        'assets/img/nbo/bosch-1.jpg',
        'assets/img/nbo/bosch-2.jpg',
        'assets/img/nbo/bosch-3.jpg',
      ],
      OilBoiler: [
        'assets/img/nbo/bosch-4.jpg',
        'assets/img/nbo/bosch-5.jpg',
        'assets/img/nbo/bosch-6.jpg',
      ],
      PelletsBoiler: [],
    },
    connectPvEnabled: {
      BrineWaterHeatPump: false,
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Nibe,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.TITLE'),
    icon: {
      AirWaterHeatPumpInside: 'assets/img/nbo/nibe-inside-thumbnail.png',
      AirWaterHeatPumpOutside: 'assets/img/nbo/nibe-thumbnail.png',
      AirWaterHeatPumpSplit: 'assets/img/nbo/nibe-thumbnail.png',
      BrineWaterHeatPump: 'assets/img/nbo/nibe-brine-thumbnail.png',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    description: {
      AirWaterHeatPumpInside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.DESCRIPTION_INSIDE'),
      AirWaterHeatPumpOutside: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.DESCRIPTION_OUTSIDE'),
      AirWaterHeatPumpSplit: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.DESCRIPTION_OUTSIDE'),
      BrineWaterHeatPump: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.NIBE.DESCRIPTION_OUTSIDE'),
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    recommended: true,
    data: {},
    images: {
      AirWaterHeatPumpInside: ['assets/img/nbo/nibe-6.jpg'],
      AirWaterHeatPumpOutside: [
        'assets/img/nbo/nibe-1.jpg',
        'assets/img/nbo/nibe-2.jpg',
        'assets/img/nbo/nibe-3.jpg',
      ],
      AirWaterHeatPumpSplit: [
        'assets/img/nbo/nibe-1.jpg',
        'assets/img/nbo/nibe-2.jpg',
        'assets/img/nbo/nibe-3.jpg',
      ],
      BrineWaterHeatPump: ['assets/img/nbo/nibe-4.png', 'assets/img/nbo/nibe-5.jpg'],
      GasBoilerFloor: [],
      GasBoilerWall: [],
      NotSpecified: [],
      OilBoiler: [],
      PelletsBoiler: [],
    },
    connectPvEnabled: {
      AirWaterHeatPumpOutside: true,
      AirWaterHeatPumpSplit: true,
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Froeling,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.FROELING.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.FROELING.TITLE'),
    icon: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: '',
      AirWaterHeatPumpSplit: '',
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: 'assets/img/nbo/froeling-thumbnail.jpg',
    },
    description: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: '',
      AirWaterHeatPumpSplit: '',
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.FROELING.DESCRIPTION_PELLETS_BOILER'
      ),
    },
    recommended: true,
    data: {},
    images: {
      AirWaterHeatPumpInside: [],
      AirWaterHeatPumpOutside: [],
      AirWaterHeatPumpSplit: [],
      BrineWaterHeatPump: [],
      GasBoilerFloor: [],
      GasBoilerWall: [],
      NotSpecified: [],
      OilBoiler: [],
      PelletsBoiler: [
        'assets/img/nbo/froeling-1.jpg',
        'assets/img/nbo/froeling-2.jpg',
        'assets/img/nbo/froeling-3.jpg',
        'assets/img/nbo/froeling-4.jpg',
        'assets/img/nbo/froeling-5.jpg',
      ],
    },
    connectPvEnabled: {
      PelletsBoiler: true,
    },
    geothermalProbeEnabled: {
      BrineWaterHeatPump: true,
    },
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Ovum,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OVUM.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OVUM.TITLE'),
    icon: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: 'assets/img/nbo/ovum-outside-thumbnail.jpg',
      AirWaterHeatPumpSplit: '',
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    description: {
      AirWaterHeatPumpInside: _(''),
      AirWaterHeatPumpOutside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.OVUM.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      AirWaterHeatPumpSplit: _(''),
      BrineWaterHeatPump: _(''),
      GasBoilerFloor: _(''),
      GasBoilerWall: _(''),
      NotSpecified: _(''),
      OilBoiler: _(''),
      PelletsBoiler: '',
    },
    recommended: false,
    data: {},
    images: {
      AirWaterHeatPumpInside: [],
      AirWaterHeatPumpOutside: ['assets/img/nbo/ovum-1.jpg', 'assets/img/nbo/ovum-2.jpg'],
      AirWaterHeatPumpSplit: [],
      BrineWaterHeatPump: [],
      GasBoilerFloor: [],
      GasBoilerWall: [],
      NotSpecified: [],
      OilBoiler: [],
      PelletsBoiler: [],
    },
    connectPvEnabled: {
      AirWaterHeatPumpOutside: true,
    },
    geothermalProbeEnabled: {},
  },
  {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum.Viessmann,
    tab: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.VIESSMANN.TAB'),
    title: _('HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.VIESSMANN.TITLE'),
    icon: {
      AirWaterHeatPumpInside: '',
      AirWaterHeatPumpOutside: 'assets/img/nbo/viessmann-outside-thumbnail.jpg',
      AirWaterHeatPumpSplit: '',
      BrineWaterHeatPump: '',
      GasBoilerFloor: '',
      GasBoilerWall: '',
      NotSpecified: '',
      OilBoiler: '',
      PelletsBoiler: '',
    },
    description: {
      AirWaterHeatPumpInside: _(''),
      AirWaterHeatPumpOutside: _(
        'HT.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE.VIESSMANN.DESCRIPTION_AIR_WATER_OUTSIDE'
      ),
      AirWaterHeatPumpSplit: _(''),
      BrineWaterHeatPump: _(''),
      GasBoilerFloor: _(''),
      GasBoilerWall: _(''),
      NotSpecified: _(''),
      OilBoiler: _(''),
      PelletsBoiler: '',
    },
    recommended: false,
    data: {},
    images: {
      AirWaterHeatPumpInside: [],
      AirWaterHeatPumpOutside: ['assets/img/nbo/viessmann-1.jpg', 'assets/img/nbo/viessmann-2.jpg'],
      AirWaterHeatPumpSplit: [],
      BrineWaterHeatPump: [],
      GasBoilerFloor: [],
      GasBoilerWall: [],
      NotSpecified: [],
      OilBoiler: [],
      PelletsBoiler: [],
    },
    connectPvEnabled: {
      AirWaterHeatPumpOutside: true,
    },
    geothermalProbeEnabled: {},
  },
];
