import { ADD_VARIANT_HT } from '@theia-cc/ht/core';
import { IAppStateHt } from '@theia-cc/ht/state';
import { NboHeatingLeadRequest } from '@api-cc';

export const INITIAL_STATE: IAppStateHt = {
  config: {
    envVariables: {
      appKey: null,
      localeStyle: null,
      enableCallbackHeader: true,
      enablePhoneHeader: true,
    },
    queryParams: {
      partnerId: null,
      compareTechnologies: null,
      origin: null,
      mode: null,
    },
    org: {
      name: null,
      contactPhone: null,
      contactEmail: null,
      logoImageUrl: null,
      logoLinkUrl: {
        de: null,
        fr: null,
        it: null,
      },
      stylesheetUrl: null,
      faviconUrl: null,
      contactOfferImageUrls: null,
      successPageUrls: {
        de: null,
        fr: null,
        it: null,
      },
    },
    styles: null,
    defaultLanguage: null,
    steps: [null],
    configLoaded: false,
    versionNumber: null,
    hubspotTrackingId: null,
    templatesHt: [],
  },
  currentLanguage: null,
  wizard: {
    isSharedOfferPreview: undefined,
    showLoadingSpinner: false,
    showOfferPreviewLoadingSpinner: false,
    allowNextStep: false,
    currentStep: null,
    previousStep: null,
    currentStepIdx: null,
    lastStep: null,
    totalSteps: null,
    currentStepData: null,
    navbar: {
      primaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      secondaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      showShareButton: false,
      photoProtocolLinkAction: ADD_VARIANT_HT,
    },
  },
  device: {
    device: null,
    browser: null,
  },
  collectedData: {
    heatingType: null,
    heatingEmission: null,
    familyMemberCount: null,
    buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum.Between1972And1997,
    buildingData: {
      aboveSea: null,
      buildingEavesHeight: null,
      buildingGroundArea: null,
      buildingLevels: null,
      buildingRidgeHeight: null,
      buildingRoofArea: null,
      buildingRoofOverhangArea: null,
      buildingRoofShape: null,
      buildingVolume: null,
      buildingWallArea: null,
    },
    powerConsumptionSelected: null,
    powerConsumptionCalculated: null,
    smartEnergy: null,
    responsibleConstructionWork: null,
    responsibleElectricalConnection: null,
    domesticWaterHeatingInstallationDesired: true,
    geothermalProbe: null,
    heatPumpType: null,
    dismantleTank: null,
    heatingArea: null,
    nboFetchedHt: [],
    nboComparison: [],
    userAddress: {
      zip: null,
      place: null,
      street: null,
      lat: null,
      lon: null,
      x: null,
      y: null,
      addressString: null,
      hasGeometry: null,
    },
    user: {
      companyName: null,
      email: null,
      emailDisclaimer: null,
      firstName: null,
      lastName: null,
      telephone: null,
      title: null,
    },
    insulation: {
      WINDOWS: null,
      FACADE: null,
      ROOF: null,
      BASEMENT: null,
    },
    nboLeadIdHt: null,
    internalLeadIdHt: null,
    previewImage: null,
    nboSelectedTemplateIdHt: null,
    leadComment: null,
    leadCallbackTime: {
      IsCallbackTimeMorning: null,
      IsCallbackTimeAfternoon: null,
      IsCallbackTimeEvening: null,
      IsCallbackTimeWeekend: null,
    },
    leadStatus: null,
    leadTag: null,
    leadTracers: null,
    showAddressFields3dFailsHt: false,
    variantIdHt: null,
    selectedTemplateHt: null,
    largeSystem: false,
    heatingPower: null,
  },
};

const { collectedData, ...rest } = INITIAL_STATE;
export const INITIAL_STATE_CORE: StateCore = rest;

export type StateCore = Omit<IAppStateHt, 'collectedData'>;
