import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH } from '@api-cc';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { HtStateAction, HtStateEffect } from '@theia-cc/ht/state';
import {
  CONSTANTS,
  ENVIRONMENT,
  ILocaleConfig,
  LOCALE_CONFIG,
  WIZARD_CONFIG,
} from '@theia-cc/shared/helpers';
import { SharedRootStepUiModule } from '@theia-cc/shared/root-step-ui';
import {
  EntryStepGuard,
  HttpErrorInterceptor,
  PartnerIdService,
  ShareLinkService,
  TranslateWithPartnerIdParser,
  getAppInitConfig,
  initAppWizardSettings,
} from '@theia-cc/shared/services';
import { BaseEffects, SharedStoreModule, WizardAction } from '@theia-cc/shared/store';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { WIZARD_CONFIG_HT } from './configs';
import { CONSTANTS_HT } from './configs/constants';
import { ParamsToCollectedDataResolverHt } from './core/params-to-collected-data.resolver';
import { ShareLinkServiceHt } from './core/share-link.service';
import { StoreModule } from './store/store.module';

export function meiertoblerInit() {
  return () => {
    return new Promise<void>(resolve => {
      const searchParams = new URLSearchParams(window.location.search);

      if (location.origin.includes('meiertobler') && searchParams.get('partnerId') !== 'mt') {
        searchParams.set('partnerId', 'mt');
        window.history.replaceState(
          {},
          document.title,
          `${window.location.pathname}?${searchParams}`
        );
      }

      resolve();
    });
  };
}

const LOCALE_CONFIG_HT: ILocaleConfig = {
  LOCALES: ['de', 'fr', 'it'],
  LOCALE_STRINGS_FORMAL: {
    de: 'assets/locales/de-formal.json',
    fr: 'assets/locales/fr-formal.json',
    it: 'assets/locales/it-formal.json',
  },
  LOCALE_STRINGS_INFORMAL: {
    de: 'assets/locales/de-informal.json',
    fr: 'assets/locales/fr-informal.json',
    it: 'assets/locales/it-informal.json',
  },
};

if (!environment.production) {
  console.log(JSON.stringify(environment));
}

@NgModule({
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    RouterModule.forRoot(
      [
        {
          path: 'digital-upload',
          loadChildren: () => import('@theia-cc/digital-upload').then(m => m.DigitalUploadModule),
          data: { trade: 'hp' }, // this is how the trade in CMS is named
        },
        {
          path: '',
          loadChildren: () => import('./steps/steps.module').then(m => m.StepsModule),
          resolve: { collectedData: ParamsToCollectedDataResolverHt },
          canActivate: [EntryStepGuard],
        },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      parser: {
        provide: TranslateParser,
        useClass: TranslateWithPartnerIdParser,
        deps: [PartnerIdService],
      },
    }),
    DeviceDetectorModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SharedRootStepUiModule,
    SharedStoreModule,
    StoreModule,
    ScullyLibModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: meiertoblerInit,
      multi: true,
    },
    getAppInitConfig(2),
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWizardSettings,
      multi: true,
      deps: [WizardAction],
    },
    { provide: ShareLinkService, useClass: ShareLinkServiceHt },
    HtStateAction,
    HtStateEffect,
    { provide: BaseEffects, useExisting: HtStateEffect },
    { provide: LOCALE_CONFIG, useValue: LOCALE_CONFIG_HT },
    { provide: WIZARD_CONFIG, useValue: WIZARD_CONFIG_HT },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: BASE_PATH, useValue: environment.apiEndpoint },
    { provide: CONSTANTS, useValue: CONSTANTS_HT },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    CookieService,
    ParamsToCollectedDataResolverHt,
  ],
})
export class AppModule {}
