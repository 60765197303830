<!--
  use hidden instead of *ngIf to ensure that angular injects the style tags into <head> at startup.
  This is necessary in order to ensure that custom CSS from config is appended to <head> afterwards so it overwrites the default styling
-->
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer-floating (clickAction)="action.buttonActions($event)"></app-footer-floating>
<!--@TODO is it usefull?-->
<!--<div style="display: none;">{{ version }}</div>-->
<ngx-loading [show]="showLoadingSpinner$ | async" [config]="spinnerOptions"></ngx-loading>

<ng-template #offerPreviewLoadingSpinnerTemplate>
  <app-offer-preview-loading-spinner
    [image]="previewImageData$ | async"></app-offer-preview-loading-spinner>
</ng-template>

<ngx-loading
  [show]="showOfferPreviewLoadingSpinner$ | async"
  [config]="offerPreviewSpinnerOptions"
  [template]="offerPreviewLoadingSpinnerTemplate"></ngx-loading>

<div class="alert-container container">
  <template #alertContainer></template>
</div>
