import {
  IConstants,
  ROOF_3D_PLUGIN_COMMON_SETTINGS,
  WizardProductKeyEnum,
} from '@theia-cc/shared/helpers';

export const CONSTANTS_HT = <IConstants>{
  wizardProductKey: WizardProductKeyEnum.ht,
  APP_MAIN_ENTRY_STEP: 'heatingtype',
  APP_ALLOWED_ENTRY_STEPS: ['heatingtype', 'contact', 'contactlargesystem', 'bookmarklet'],
  moduleLifetimeYears: 30,
  minRoofArea: 23,
  maxRoofArea: 150,
  basePricePerInstallation: 7906,
  pricePerAdditionalKwp: 900,
  roofAreaPrices: {
    large: "32'000",
    medium: "21'000",
    small: "13'000",
  },
  maxPowerPerModule: 0.305,
  areaPerModule: 1.7,
  basePowerConsumption: 1850,
  powerConsumptionPerPerson: 850,
  powerConsumptionPerApartment: 3300,
  kwhPerRoofM2: 178,
  slopeKwpPerM2: 0.165,
  flatKwpPerM2: 0.148,
  minKwp: 30,
  powerCostLow: 0.1,
  powerCostHigh: 0.17,
  averageProductionKwp: 950,
  apartmentBuildingHighDrainSystemsKwh: {
    HEATPUMP_OR_ELECTROBOILER: 6000,
    EMOBILITY_CHARGING: 10000,
    POOL_SAUNA: 5000,
  },
  maxBuildingArea: 158,
};

export const ROOF_3D_PLUGIN_HT_SETTINGS = {
  ...ROOF_3D_PLUGIN_COMMON_SETTINGS,
  roofClickCallback: () => {},
  roofColoring: false,
  showBestRoof: false,
  roofSelectable: 0,
};
