import { NboHeatingLeadRequest, NboHeatingVariantRequest } from '@api-cc';
import { ADD_VARIANT_HT, IInsulation, INboFetched, ITemplateHt } from '@theia-cc/ht/core';
import {
  IBuildingSummary,
  IConfigBase,
  IConfigQueryParamsBase,
  ILeadMeta,
  INavBarBase,
  IRelatedLead,
  IUserInfo,
  IWizardBase,
} from '@theia-cc/shared/models';
import { IChoiceButton } from '@theia-cc/shared/step-ui';
import { IAppStateBase } from '@theia-cc/shared/store';
import { HtStateAction } from './ht-state.action';

interface ICollectedData {
  heatingType: NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum;
  heatingEmission: NboHeatingLeadRequest.CurrentHeatDissipationEnum;
  familyMemberCount: number;
  buildingData: IBuildingSummary;
  buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum;
  insulation: IInsulation;
  nboFetchedHt: INboFetched[];
  nboComparison: INboFetched[];
  internalLeadIdHt: number;
  nboLeadIdHt: string;
  variantIdHt: number;
  previewImage: string;
  powerConsumptionSelected: number;
  powerConsumptionCalculated: number;
  smartEnergy: NboHeatingVariantRequest.SmartEnergyConnectionEnum;
  responsibleConstructionWork: NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum;
  responsibleElectricalConnection: NboHeatingVariantRequest.ResponsibleElectricalConnectionEnum;
  geothermalProbe: NboHeatingVariantRequest.GeothermalProbeEnum;
  heatPumpType: NboHeatingVariantRequest.HeatingTypeEnum;
  dismantleTank: NboHeatingVariantRequest.DismantlingTankEnum;
  domesticWaterHeatingInstallationDesired: boolean;
  heatingArea: number;
  nboSelectedTemplateIdHt: NboHeatingVariantRequest.HeatingManufacturerEnum;
  selectedTemplateHt: any;
  showAddressFields3dFailsHt: boolean;
  largeSystem: boolean;
  heatingPower: number;
}

export interface IConfigQueryParamsHt extends IConfigQueryParamsBase {
  compareTechnologies: string;
}

export interface IConfigHt extends IConfigBase {
  queryParams?: IConfigQueryParamsHt;
  templatesHt: ITemplateHt[];
  buildingAgeItems?: IChoiceButton[];
  insulationTypes?: IChoiceButton[];
}

export interface INavBarHt extends INavBarBase {
  photoProtocolLinkAction: string;
}

export interface IWizardHt extends IWizardBase {
  navbar: INavBarHt;
}

export type ICollectedDataHt = ICollectedData & IUserInfo & ILeadMeta & IRelatedLead;

export interface IAppStateHt extends IAppStateBase {
  config: IConfigHt;
  collectedData: ICollectedDataHt;
  wizard: IWizardHt;
}

const INITIAL_STATE_COLLECTED_DATA: ICollectedData = {
  heatingType: null,
  heatingEmission: null,
  familyMemberCount: null,
  buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum.Between1972And1997,
  buildingData: {
    aboveSea: null,
    buildingEavesHeight: null,
    buildingGroundArea: null,
    buildingLevels: null,
    buildingRidgeHeight: null,
    buildingRoofArea: null,
    buildingRoofOverhangArea: null,
    buildingRoofShape: null,
    buildingVolume: null,
    buildingWallArea: null,
  },
  powerConsumptionSelected: null,
  powerConsumptionCalculated: null,
  smartEnergy: null,
  responsibleConstructionWork: null,
  responsibleElectricalConnection: null,
  geothermalProbe: null,
  heatPumpType: null,
  dismantleTank: null,
  domesticWaterHeatingInstallationDesired: true,
  heatingArea: null,
  nboFetchedHt: [],
  nboComparison: [],
  insulation: {
    WINDOWS: null,
    FACADE: null,
    ROOF: null,
    BASEMENT: null,
  },
  nboLeadIdHt: null,
  internalLeadIdHt: null,
  variantIdHt: null,
  previewImage: null,
  nboSelectedTemplateIdHt: null,
  selectedTemplateHt: null,
  showAddressFields3dFailsHt: false,
  largeSystem: false,
  heatingPower: null,
};

export const INITIAL_STATE_WIZARD_HT: IWizardHt = {
  isSharedOfferPreview: undefined,
  showLoadingSpinner: false,
  showOfferPreviewLoadingSpinner: false,
  allowNextStep: false,
  currentStep: null,
  previousStep: null,
  currentStepIdx: null,
  lastStep: null,
  totalSteps: null,
  currentStepData: null,
  navbar: {
    primaryButton: {
      name: null,
      action: null,
      facet: null,
      disabled: null,
      hidden: null,
    },
    secondaryButton: {
      name: null,
      action: null,
      facet: null,
      disabled: null,
      hidden: null,
    },
    showShareButton: true,
    photoProtocolLinkAction: ADD_VARIANT_HT,
  },
};

export const collectedDataReducerHt = (
  state: ICollectedData = INITIAL_STATE_COLLECTED_DATA,
  action: { type: any; payload: any | IInsulation }
): ICollectedData => {
  const { payload, type } = action;

  switch (type) {
    case HtStateAction.STORE_HEATING_TYPE:
      return {
        ...state,
        heatingType: payload,
      };

    case HtStateAction.STORE_HEAT_EMISSION:
      return {
        ...state,
        heatingEmission: payload,
      };

    case HtStateAction.STORE_FAMILY_MEMBER_COUNT:
      return {
        ...state,
        familyMemberCount: payload,
      };

    case HtStateAction.STORE_BUILDING_AGE:
      return {
        ...state,
        buildingAge: payload,
      };

    case HtStateAction.STORE_HEATING_AREA:
      return {
        ...state,
        heatingArea: payload,
      };

    case HtStateAction.STORE_INSULATION:
      return {
        ...state,
        insulation: payload,
      };

    case HtStateAction.STORE_POWER_CONSUMPTION_SELECTED:
      return {
        ...state,
        powerConsumptionSelected: payload,
      };

    case HtStateAction.STORE_POWER_CONSUMPTION_CALCULATED:
      return {
        ...state,
        powerConsumptionCalculated: payload,
      };

    case HtStateAction.STORE_SMART_ENERGY:
      return {
        ...state,
        smartEnergy: payload,
      };

    case HtStateAction.STORE_RESPONSIBLE_CONSTRUCTION_WORK:
      return {
        ...state,
        responsibleConstructionWork: payload,
      };

    case HtStateAction.STORE_RESPONSIBLE_ELECTRICAL_CONNECTION:
      return {
        ...state,
        responsibleElectricalConnection: payload,
      };

    case HtStateAction.STORE_GEOTHERMAL_PROBE:
      return {
        ...state,
        geothermalProbe: payload,
      };

    case HtStateAction.HT_STORE_HEAT_PUMP_TYPE:
      return {
        ...state,
        heatPumpType: payload,
      };

    case HtStateAction.STORE_DISMANTLE_TANK:
      return {
        ...state,
        dismantleTank: payload,
      };

    case HtStateAction.STORE_DOMESTIC_WATER_HEATING_INSTALLATION_DESIRED:
      return {
        ...state,
        domesticWaterHeatingInstallationDesired: payload,
      };

    case HtStateAction.HT_STORE_NBO_LEAD_ID:
      return {
        ...state,
        nboLeadIdHt: payload,
      };

    case HtStateAction.HT_STORE_INTERNAL_LEAD_ID:
      return {
        ...state,
        internalLeadIdHt: payload,
      };

    case HtStateAction.HT_STORE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplateHt: payload,
        nboSelectedTemplateIdHt: payload?.template?.id,
      };

    case HtStateAction.HT_NBO_FETCHING_STORE:
      return {
        ...state,
        nboFetchedHt: [...state.nboFetchedHt, payload],
      };

    case HtStateAction.HT_NBO_COMPARISON_STORE: {
      return {
        ...state,
        nboComparison: payload,
      };
    }

    case HtStateAction.HT_NBO_FETCHING_CLEAR:
      return {
        ...state,
        nboFetchedHt: [],
      };

    case HtStateAction.HT_NBO_COMPARISON_CLEAR:
      return {
        ...state,
        nboComparison: [],
      };

    case HtStateAction.HT_STORE_SHOW_ADDRESS_FIELDS_3D_FAILS:
      return {
        ...state,
        showAddressFields3dFailsHt: payload,
      };

    case HtStateAction.HT_SET_CONFIG_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    case HtStateAction.HT_STORE_ROOF_3D_IMAGE:
      return {
        ...state,
        previewImage: payload,
      };

    case HtStateAction.HT_STORE_VARIANT_ID:
      return {
        ...state,
        variantIdHt: payload,
      };

    case HtStateAction.STORE_LARGE_SYSTEM:
      return {
        ...state,
        largeSystem: action.payload,
      };

    case HtStateAction.STORE_HEATING_POWER:
      return {
        ...state,
        heatingPower: action.payload,
      };

    default:
      return state;
  }
};

export const wizardReducerHt = (state: IWizardHt = INITIAL_STATE_WIZARD_HT, action) => {
  switch (action.type) {
    case HtStateAction.STORE_PHOTO_PROTOCOL_ACTION:
      return {
        ...state,
        navbar: {
          ...state.navbar,
          photoProtocolLinkAction: action.payload,
        },
      };

    default:
      return state;
  }
};
